<template>
  <section v-if="procedure">
    <fw-panel :title="$t('calendar')" featured :loading="savingData" after-loading-checked> </fw-panel>

    <fw-panel :title="$t('publicationDates')" boxed="sm" class="my-4" custom-class="bg-white flex flex-col gap-3">
      <template #toolbar>
        <div class="flex gap-1 items-center">
          <div v-if="editMode && $v.$error" class="p-1 mx-5 text-red-500 flex items-center gap-1 text-sm font-medium">
            <fw-icon-error-warning class="w-6 h-6"></fw-icon-error-warning>
            <span class="hidden lg:block">{{ $t('thereAreErrors') }}</span>
          </div>
          <div v-if="canEdit" class="flex gap-5">
            <fw-button
              :type="!editMode ? 'link' : 'link-light'"
              :disabled="savingData"
              :loading="savingData"
              @click.native="toggleEditMode()"
            >
              {{ editMode ? $t('close') : $t('edit') }}
            </fw-button>
            <fw-button
              v-if="editMode"
              type="primary"
              :disabled="savingData"
              :loading="savingData"
              @click.native="saveProc()"
              >{{ $t('save') }}</fw-button
            >
          </div>
        </div>
      </template>
      <div class="flex flex-col sm:flex-row gap-5">
        <div class="sm:w-1/3">
          <span class="font-semibold text-gray-500 mb-1 text-xs">{{
            isScholarship ? 'Publicação Euraxess' : 'Publicação no DR'
          }}</span>
          <div v-if="!editMode || !canEdit" class="font-medium">
            {{ publishedDrDate | formatDate }}
          </div>
          <b-datepicker
            v-else
            v-model="$v.publishedDrDate.$model"
            :placeholder="$t('chooseDate')"
            icon="calendar-alt"
            locale="pt-PT"
            trap-focus
            :class="{
              error: $v.publishedDrDate.$error,
            }"
            :disabled="!canEdit && editMode"
            @icon-right-click="clearDate('published_dr_date')"
          >
          </b-datepicker>
          <fw-tip v-if="$v.publishedDrDate.$error" error>
            {{ $t('enterDate') }}
          </fw-tip>
        </div>

        <div class="sm:w-1/3">
          <span class="font-semibold text-gray-500 mb-1 text-xs">{{ $t('applicationsOpening') }}</span>
          <div v-if="!editMode || !canEdit" class="font-medium">{{ publishStart | formatDate }}</div>
          <b-datepicker
            v-else
            v-model="$v.publishStart.$model"
            :class="{
              error: $v.publishStart.$error,
            }"
            :placeholder="$t('chooseDate')"
            icon="calendar-alt"
            locale="pt-PT"
            trap-focus
            :disabled="!canEdit && editMode"
            @input="publishStartChanged()"
            @icon-right-click="publishStart = null"
          >
          </b-datepicker>

          <fw-tip v-if="$v.publishStart.$error" error>
            <span v-if="!$v.publishStart.min">{{ $t('enterLaterOpeningDateDate') }}</span>
            <span v-else>{{ $t('enterOpeningDate') }}</span>
          </fw-tip>
        </div>
        <div class="sm:w-1/3">
          <span class="font-semibold text-gray-500 mb-1 text-xs">{{ $t('closingApplications') }}</span>
          <div v-if="!editMode || !canEdit" class="font-medium">{{ publishEnd | formatDate }}</div>
          <b-datepicker
            v-else
            v-model="$v.publishEnd.$model"
            :class="{
              error: $v.publishEnd.$error,
            }"
            :placeholder="$t('chooseDate')"
            locale="pt-PT"
            icon="calendar-alt"
            trap-focus
            position="is-bottom-left"
            :disabled="!canEdit && editMode"
            @icon-right-click="publishEnd = null"
          >
          </b-datepicker>
          <fw-tip v-if="$v.publishEnd.$error" error>
            <span v-if="!$v.publishEnd.min">{{ $t('enterLaterClosingDateDate') }}</span>
            <span v-else>{{ $t('enterClosingDate') }}</span>
          </fw-tip>
        </div>
      </div>
    </fw-panel>

    <fw-panel :title="$t('homologation')" boxed="sm" class="my-4" custom-class="bg-white flex flex-col gap-3">
      <template #toolbar>
        <div class="flex gap-1 items-center">
          <div
            v-if="editModeHomologation && $v.homologationDate.$error"
            class="p-1 mx-5 text-red-500 flex items-center gap-1 text-sm font-medium"
          >
            <fw-icon-error-warning class="w-6 h-6"></fw-icon-error-warning>
            <span class="hidden lg:block">{{ $t('thereAreErrors') }}</span>
          </div>
          <div v-if="canEditHomologationDate" class="flex gap-5">
            <fw-button
              :type="!editModeHomologation ? 'link' : 'link-light'"
              :disabled="savingData"
              :loading="savingData"
              @click.native="toggleEditModeHomologation()"
            >
              {{ editModeHomologation ? $t('close') : $t('edit') }}
            </fw-button>
            <fw-button
              v-if="editModeHomologation"
              type="primary"
              :disabled="savingData"
              :loading="savingData"
              @click.native="saveProc(homologationPayload())"
              >{{ $t('save') }}</fw-button
            >
          </div>
        </div>
      </template>

      <div class="w-1/3">
        <fw-label>{{ $t('homologationDate') }}</fw-label>
        <div v-if="!editModeHomologation && homologationDate">{{ homologationDate | formatDate }}</div>
        <div v-else-if="!editModeHomologation && !homologationDate">{{ $t('notDefined') }}</div>
        <b-datepicker
          v-else
          v-model="homologationDate"
          :placeholder="$t('chooseHomologationDate')"
          icon="calendar-alt"
          locale="pt-PT"
          trap-focus
          :disabled="!canEditHomologationDate && editModeHomologation"
          @icon-right-click="homologationDate = null"
        >
        </b-datepicker>
      </div>
      <fw-panel-info clean>
        18 meses após esta data as atas do concurso deixarão de estar públicas.
      </fw-panel-info>
    </fw-panel>

    <fw-panel :title="$t('meetingsDates')" custom-class="bg-white" class="my-5">
      <ExpandList
        :add-title="$t('addMeeting')"
        :empty="!meetings || meetings.length < 1"
        :show-add-items="canAddNewMeeting"
        @add-element="newMeeting"
      >
        <template #list>
          <fw-panel-info v-if="(!meetings || meetings.length < 1) && !canAddNewMeeting" type="basic" centered
            >{{ $t('noMeetings') }}.</fw-panel-info
          >
          <template v-for="(meeting, m) in meetings">
            <div
              v-if="!meeting._destroy"
              :key="'meeting_' + m"
              class="p-4 border-b border-gray-100 flex justify-end items-center"
              :class="{ 'hover:bg-gray-100': canEdit }"
            >
              <div class="flex-1 flex gap-2 items-center">
                <div>
                  <fw-icon-calendar class="w-5 h-5" />
                </div>
                <div>
                  <fw-heading size="h4"
                    >{{ meeting.date | formatDayWithTime }}
                    <div class="w-2 h-2 bg-gray-200 mx-1.5 rounded-full inline-block mb-0.5"></div>
                    {{ $t(`meetingsGoals.${meeting.goal}`) }}</fw-heading
                  >
                </div>
              </div>
              <div
                v-if="
                  canEditMeetings &&
                    !(meeting.goal == 'seriation-interview'
                      ? procedure.interview_started
                      : meeting.goal == 'seriation-curriculum'
                      ? procedure.admission_started
                      : procedure.status == 'closed')
                "
              >
                <b-dropdown aria-role="list" position="is-bottom-left">
                  <fw-button-dropdown slot="trigger" :chevron="false" type="xlight" class="flex flex-col" label="Ações">
                    <fw-icon-more class="w-5 h-5" />
                  </fw-button-dropdown>
                  <b-dropdown-item custom aria-role="menuitem" class="paddingless">
                    <fw-button
                      type="basic-action"
                      label="Editar reunião"
                      size="sm"
                      custom-class="w-full"
                      @click.native="editMeeting(m)"
                    >
                      {{ $t('edit') }}
                    </fw-button>
                  </b-dropdown-item>
                  <b-dropdown-item custom aria-role="menuitem" class="paddingless">
                    <fw-button
                      type="basic-action"
                      size="sm"
                      custom-class="w-full"
                      label="Eliminar reunião"
                      @click.native="deleteMeeting(m)"
                    >
                      {{ $t('delete') }}
                    </fw-button>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
          </template>
        </template>
      </ExpandList>
    </fw-panel>

    <CandidatesInterviewInfo
      v-if="procedure.with_interview && procedure.curriculum_closed"
      :procedure="procedure"
      :people="candidates"
      :loading="loadingData.setCandidatesInterview"
      :can-edit="canEditInterviewInfo"
      @set-candidates-interview="setCandidatesInterview"
    />

    <fw-panel-info debug label="Meetings data (raw)" class="my-5">
      <json-viewer :value="meetings"></json-viewer>
    </fw-panel-info>
  </section>
</template>

<script>
import ExpandList from '@/fw-modules/fw-core-vue/ui/components/lists/ExpandList'
import CandidatesInterviewInfo from '@/components/panels/CandidatesInterviewInfo'
import dayjs from 'dayjs'
import { required, minValue, requiredIf, numeric } from 'vuelidate/lib/validators'

export default {
  components: {
    ExpandList,
    CandidatesInterviewInfo,
  },

  props: {
    procedure: {
      type: Object,
      default: () => {
        return {}
      },
    },

    errors: {
      type: Object,
      default: () => {
        return {}
      },
    },

    loadingData: {
      type: Object,
      default: () => {
        return {}
      },
    },

    isValid: {
      type: Boolean,
    },

    canEdit: {
      type: Boolean,
      default: true,
    },

    canEditHomologationDate: {
      type: Boolean,
      default: true,
    },

    canEditMeetings: {
      type: Boolean,
      default: true,
    },

    canAddNewMeeting: {
      type: Boolean,
      default: true,
    },

    view: {
      type: String,
      default: 'metadata',
    },

    savingData: {
      type: Boolean,
      default: false,
    },

    canEditInterviewInfo: {
      type: Boolean,
      default: false,
    },

    candidates: {
      type: Array,
      default: () => {
        return []
      },
    },
  },

  data() {
    return {
      activeModal: null,
      editMode: this.canEdit && this.procedure.status === 'draft',
      editModeHomologation: this.canEditHomologationDate && this.procedure.status === 'draft',
      publishedDrDate: null,
      publishStart: null,
      publishEnd: null,
    }
  },

  validations() {
    return {
      publishedDrDate: { required },
      publishStart: { required, min: minValue(this.publishedDrDate) },
      publishEnd: { required, min: minValue(this.publishStart) },
      homologationDate: {},
      meetings: {
        $each: {
          date: { required },
          duration: { numeric },
          goal: { required },
          type: { required },
          uc_meetings_id: {
            required: requiredIf(function(nestedModel) {
              return nestedModel.type != 'in_person'
            }),
          },
        },
      },
    }
  },

  computed: {
    isScholarship() {
      return this.procedure !== null && this.procedure.type == 'scholarships'
    },

    meetings() {
      return this.procedure.meetings
    },

    canDownloadPresenceFile() {
      return this.currentViewChecks && this.currentViewChecks.meeting_closed
    },
  },

  watch: {
    '$v.$invalid'(newInvalid) {
      this.$emit('update:isValid', !newInvalid)
    },
  },

  created() {
    this.publishedDrDate = this.procedure.published_dr_date
    this.publishStart = this.procedure.publish_start
    this.publishEnd = this.procedure.publish_end
    this.homologationDate = this.procedure.homologation_date
    this.$emit('update:isValid', !this.$v.$invalid)
  },

  methods: {
    setCandidatesInterview(candidates) {
      this.$emit('set-candidates-interview', null, candidates)
    },

    publishStartChanged() {
      if (!this.publishEnd && this.publishStart) {
        this.publishEnd = dayjs(this.publishStart)
          .add(10, 'day')
          .toDate()
      }
    },

    newMeeting() {
      this.$emit('new-meeting')
    },

    deleteMeeting(pos) {
      this.$emit('delete-meeting', pos)
    },

    editMeeting(pos) {
      this.$emit('edit-meeting', pos)
    },

    toggleEditMode() {
      this.editMode = !this.editMode
    },

    toggleEditModeHomologation() {
      this.editModeHomologation = !this.editModeHomologation
    },

    closeModal() {
      this.activeModal = null
    },

    homologationPayload() {
      let payload = {}
      if (this.homologationDate) {
        payload.homologation_date = dayjs(this.homologationDate).format('YYYY-MM-DD')
      }

      return payload
    },

    saveProc(payload = null) {
      if (payload && payload.homologation_date) {
        this.$v.homologationDate.$touch()
        if (this.$v.homologationDate.$invalid) return
        this.$emit('save-procedure', payload)
      } else {
        this.$v.$touch()

        if (!this.$v.$invalid) {
          if (!payload) {
            payload = {}
            if (this.publishedDrDate) {
              payload.published_dr_date = dayjs(this.publishedDrDate).format('YYYY-MM-DD')
            }
            if (this.publishStart) {
              payload.publish_start = dayjs(this.publishStart).format('YYYY-MM-DD')
            }
            if (this.publishEnd) {
              payload.publish_end = dayjs(this.publishEnd).format('YYYY-MM-DD')
            }
          }

          this.$emit('save-procedure', payload)
        }
      }
    },
  },
}
</script>

<i18n>
{
  "pt": {
    "calendar": "Calendarização",
    "publicationDates": "Datas de publicação",
    "thereAreErrors": "Existem erros no formulário",
    "save": "Guardar",
    "cancel": "Cancelar",
    "edit": "Editar",
    "delete": "Eliminar",
    "close": "Fechar edição",
    "chooseDate": "Escolha uma data",
    "enterDate": "Insira a data de publicação",
    "applicationsOpening": "Abertura a candidaturas",
    "enterOpeningDate": "Insira a data de abertura a candidaturas",
    "enterLaterOpeningDateDate": "Insira uma data superior a data publicação",
    "enterClosingDate": "Insira a data de fecho de candidaturas",
    "enterLaterClosingDateDate": "Insira uma data superior á data de abertura",
    "closingApplications": "Fecho de candidaturas",
    "meetingsDates": "Datas das reuniões",
    "addMeeting": "Adicionar Reunião",
    "noMeetings": "Sem reuniões calendarizadas",
    "meetingsGoals": {
      "jury": "Definição do júri",
      "criteria": "Definição de critérios",
      "admission": "Admissão de candidatos",
      "seriation-curriculum": "Avaliação curricular",
      "seriation-interview": "Entrevista a candidatos",
      "complaints": "Audiência(s) de interessados"
    },
    "homologationDate": "Data de homologação",
    "chooseHomologationDate": "Escolha a data de homologação",
    "notDefined": "Não foi definido",
    "homologation": "Homologação"
  },
  "en": {
    "calendar": "Schedule",
    "publicationDates": "Publication dates",
    "thereAreErrors": "There are errors in the form",
    "save": "Save",
    "cancel": "Cancel",
    "edit": "Edit",
    "delete": "Delete",
    "close": "Close edition",
    "chooseDate": "Choose a date",
    "enterDate": "Enter the publication date",
    "applicationsOpening": "Opening to applications",
    "enterOpeningDate": "Enter the opening date for applications",
    "enterLaterOpeningDateDate": "Enter a date higher than the publication date",
    "enterClosingDate": "Enter the application closing date",
    "enterLaterClosingDateDate": "Enter a date greater than the opening date",
    "closingApplications": "Close of applications",
    "meetingsDates": "Meeting Dates",
    "addMeeting": "Add Meeting",
    "noMeetings": "No meetings scheduled",
    "meetingsGoals": {
      "jury": "Jury Definition",
      "criteria": "Definition of criteria",
      "admission": "Admission of candidates",
      "seriation-curriculum": "Curriculum evaluation",
      "seriation-interview": "Candidate interviews",
      "complaints": "Stakeholder Hearing"
    },
    "homologationDate": "Homologation date",
    "chooseHomologationDate": "Choose homologation date",
    "notDefined": "Not defined",
    "homologation": "Homologation"
  }
}
</i18n>
